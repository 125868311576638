import { call, all, takeEvery, put } from "redux-saga/effects";

import * as API_REPORT from "./services";
import reportActions from "./actions";

function* FETCH_REPORT_DATA({ payload }) {
  const { reportId } = payload;
  const report = yield call(API_REPORT.getReport, reportId);
  yield put({
    type: reportActions.SET_REPORT,
    payload: report,
  });
}

export default function* reportSagas() {
  yield all([takeEvery(reportActions.FETCH_REPORT_DATA, FETCH_REPORT_DATA)]);
}
