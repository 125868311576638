import { Suspense, lazy } from "react";

import { classList } from "@utils";

import { Navigate, Route, Routes } from "react-router-dom";
import FaqPageLogIn from "../../pages/faqPageLogIn";
import PrivacyPolicyPage from "../../pages/privacy";
import TermOfService from "../../pages/termOfService";
import { useSelector } from "react-redux";

const Header = lazy(() => import("./header"));
const SideNav = lazy(() => import("./sideNav"));
const Footer = lazy(() => import("../../components/footer"));
const Loading = lazy(() => import("../../components/loading"));
const Dashboard = lazy(() => import("../../pages/dashboard"));
const Formations = lazy(() => import("../../pages/formations"));
const FormationDetail = lazy(() => import("../../pages/formationDetail"));
const MesBiens = lazy(() => import("../../pages/mesBiens"));
const InfoPersonnelles = lazy(() => import("../../pages/monCompte"));
const EvaluationBien = lazy(() => import("../../pages/evaluationBien"));
const NewRapport = lazy(() => import("../../pages/reports"));
const Support = lazy(() => import("../../pages/contact/Support"));

const PrivateLayout = () => {
  const settings = useSelector((state) => state.layout.settings);

  return (
    <div>
      <div
        className={`${classList({
          "app-admin-wrap layout-sidebar-large": true,
          "sidenav-open": settings.layout1Settings.leftSidebar.open,
        })} sidebar-dark-purple`}
      >
        <Header></Header>
        <SideNav />

        <div
          className={classList({
            "main-content-wrap d-flex flex-column": true,
            "sidenav-open": settings.layout1Settings.leftSidebar.open,
          })}
        >
          <Suspense fallback={<Loading />}>
            <div className="main-content p-5 bg-gray-100 min-height-80vh">
              {
                <Routes>
                  <Route path={"/dashboard"} element={<Dashboard />} />
                  <Route path={"/formations"} element={<Formations />} />
                  <Route
                    path={"/formation/:id"}
                    element={<FormationDetail />}
                  />
                  <Route path={"/moncompte"} element={<InfoPersonnelles />} />
                  <Route path={"/mes-biens"} element={<MesBiens />} />
                  <Route
                    path={"/evaluation-bien"}
                    element={<EvaluationBien />}
                  />
                  <Route path={"/vmz/:id"} element={<NewRapport />} />
                  <Route path={"/faqIn"} element={<FaqPageLogIn />} />
                  <Route path={"/dataPolicy"} element={<PrivacyPolicyPage />} />
                  <Route path={"/termofservice"} element={<TermOfService />} />
                  <Route path={"/contact"} element={<Support />} />
                  <Route
                    path="*"
                    element={<Navigate to="/dashboard" replace />}
                  />
                </Routes>
              }
            </div>
          </Suspense>
          {settings.footer.show && <Footer></Footer>}
        </div>
      </div>
    </div>
  );
};

export default PrivateLayout;
