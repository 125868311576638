import { call, all, takeEvery, put } from "redux-saga/effects";
import formationActions from "./actions";
import * as API from "./services";

function* GET_FORMATIONS(action) {
  try {
    yield put({
      type: formationActions.SET_LOADING,
      payload: true,
    });
    const formations = yield call(API.getFormations);
    yield put({
      type: formationActions.SET_FORMATIONS,
      payload: {
        formations,
      },
    });
  } catch (e) {}
}

function* GET_FORMATION_BY_ID(action) {
  try {
    const { id } = action.payload;
    const formation = yield call(API.getFormationById, id);
    yield put({
      type: formationActions.SET_SELECTED_FORMATION,
      payload: formation,
    });
  } catch (e) {}
}

function* formationSagas() {
  yield all([
    takeEvery(formationActions.FETCH_FORMATIONS, GET_FORMATIONS),
    takeEvery(formationActions.FETCH_FORMATION_BY_ID, GET_FORMATION_BY_ID),
  ]);
}

export default formationSagas;
