import actions from "./actions";

const initialState = {
  auth: { isEmpty: true, isLoaded: false, uid: null },
  data: {},
  profile: {},
  mesEvaluations: [],
  isMore: false,
  nextEvaluation: null,
  loadingMore: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SET_ISLOADED:
      return { ...state, auth: { isLoaded: action.payload, ...state.auth } };
    default:
      return state;
  }
}
