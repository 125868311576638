import { all } from "redux-saga/effects";
import headerSaga from "./header/sagas";
import homeSagas from "./home/sagas";
import estimationSagas from "./estimation/sagas";
import userSagas from "./user/sagas";
import dashboardSagas from "./dashboard/sagas";
import formationSagas from "./formation/sagas";
import reportSagas from "./report/sagas";
export default function* rootSaga() {
  yield all([
    headerSaga(),
    homeSagas(),
    estimationSagas(),
    userSagas(),
    dashboardSagas(),
    formationSagas(),
    reportSagas(),
  ]);
}
