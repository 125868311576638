import { call, all, takeEvery, put, select } from "redux-saga/effects";
import * as API_ESTIMATION from "../estimation/services";
import * as API_USER from "./services";
import actions from "./actions";
function* GET_CURRENT_USER({ payload }) {
  const { user = {} } = payload;
  const profile = yield call(API_ESTIMATION.getUserWithUID, user.uid);
  if (user?.uid) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        profile: profile,
        auth: {
          isEmpty: false,
          isLoaded: true,
          uid: user.uid,
        },
      },
    });
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        auth: {
          isEmpty: true,
          isLoaded: true,
        },
      },
    });
  }
}

function* GET_MY_EVALUATIONS({ payload }) {
  const { uid } = payload;
  const { mesEvaluations, isMore, nextEvaluation } = yield call(
    API_USER.getMyEvaluations,
    uid
  );

  yield put({
    type: actions.SET_STATE,
    payload: {
      mesEvaluations,
      isMore,
      nextEvaluation,
    },
  });
}

function* GET_MORE_EVALUATIONS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingMore: true,
    },
  });
  const state = yield select();
  const { mesEvaluations, nextEvaluation } = state.user;

  const { uid } = payload;
  const {
    newEvaluations,
    isMore,
    nextEvaluation: nextEvaluation2,
  } = yield call(API_USER.getMoreEvaluations, uid, nextEvaluation);

  yield put({
    type: actions.SET_STATE,
    payload: {
      mesEvaluations: [...mesEvaluations, ...newEvaluations],
      isMore,
      nextEvaluation: nextEvaluation2,
      loadingMore: false,
    },
  });
}

function* DELETE_EVALUATION({ payload }) {
  const { evaluationId } = payload;
  const res = yield call(API_USER.deleteEvaluation, evaluationId);
  if (res) {
    const state = yield select();

    const evaluations = state.user.mesEvaluations;

    const filteredEvaluations = evaluations.filter(
      (evaluation) => evaluation.id !== evaluationId
    );

    yield put({
      type: actions.SET_STATE,
      payload: {
        mesEvaluations: filteredEvaluations,
      },
    });
  }
}

export default function* userSagas() {
  yield all([
    takeEvery(actions.GET_CURRENT_USER, GET_CURRENT_USER),
    takeEvery(actions.SET_MES_EVALUATIONS, GET_MY_EVALUATIONS),
    takeEvery(actions.DELETE_EVALUATION, DELETE_EVALUATION),
    takeEvery(actions.GET_MORE_EVALUATIONS, GET_MORE_EVALUATIONS),
  ]);
}
