import { call, takeEvery, put } from "redux-saga/effects";
import * as API from "./services";
import actions from "./actions";
import headerActions from "./actions";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* GET_LATEST_ESTIMATIONS() {
  try {
    const response = yield call(API.getLatestEstimation);
    yield put({
      type: headerActions.SET_STATE,
      payload: {
        recentEstimates: response || [],
      },
    });
  } catch (e) {
    console.log("getLatestEstimation -> error:", e);
  }
}

function* homeSagas() {
  yield takeEvery(actions.GET_LATEST_ESTIMATIONS, GET_LATEST_ESTIMATIONS);
}

export default homeSagas;
