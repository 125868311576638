import axios from "axios";
import { auth, db, functions } from "../../firebaseService";
import { RecaptchaVerifier } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { collection, getDocs } from "firebase/firestore";

const baseUrlPro = "https://us-central1-ziaapp-ac0eb.cloudfunctions.net";

const getNews = async () => {
  return axios
    .get(`${baseUrlPro}/GetNews`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
};

export const getUsers = async () => {
  const querySnapshot = await getDocs(collection(db, "users"));
  const users = [];
  querySnapshot.forEach((item) => {
    const data = item.data();
    users.push({ id: item.id, ...data });
  });
  return users;
};

export const captchaVerifier = async (
  setCaptchaSuccess,
  setValue,
  id,
  isResend
) => {
  return new RecaptchaVerifier(
    auth,
    id ? id : "recaptcha",
    {
      size: "normal",
      callback: () => {
        setCaptchaSuccess(true);
        if (isResend) setValue("done");
        else setValue("captcha", "done");
      },
      "expired-callback": () => {
        setCaptchaSuccess(false);
        if (isResend) setValue("done");
        else setValue("captcha", "done");
      },
    },
    auth.app
  );
};
export const onConfirmCode = async (confirmationResult, code) => {
  return confirmationResult
    .confirm(code)
    .then((res) => {
      return res.user;
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const zohoPostLead = (data) => {
  const postZoho = httpsCallable(functions, "useZohoPostNewLead");
  postZoho(data);
};

export { getNews };
