import { db } from "../../firebaseService";
import { collection, query, getDocs, getDoc, doc } from "firebase/firestore";

export const getFormations = async () => {
  const querySnapshot = await getDocs(query(collection(db, "formations")));
  const formations = [];

  querySnapshot.forEach((item) => {
    const data = item.data();
    formations.push({ id: item.id, ...data });
  });
  return formations;
};

export const getFormationById = async (id) => {
  const docSnap = await getDoc(doc(db, "formations", id));
  if (docSnap.exists) {
    return { id: docSnap.id, ...docSnap.data() };
  } else {
    return false;
  }
};
