// import firebase from "firebase/compat/app";
import { functions, auth, db } from "../../firebaseService";
import { httpsCallable } from "firebase/functions";
import {
  signInWithPhoneNumber,
  PhoneAuthProvider,
  signInWithCredential as signInWithCredentialFirebase,
} from "firebase/auth";
import { collection, setDoc, doc, updateDoc, getDoc } from "firebase/firestore";
import Swal from "sweetalert2";

export const getEstimation = async (data = {}) => {
  const address = {
    postalCode: data.postcode,
    address: `${data.StreetNumber?.long_name} ${data.StreetName?.long_name}`,
  };
  const getPropertyDetailInfoNew = httpsCallable(
    functions,
    "GetProperyDetailInfoNew"
  );
  return getPropertyDetailInfoNew(address)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return false;
    });
};

export const evaluerBien = async (data) => {
  const getEvalueBien = httpsCallable(functions, "EvaluerBienNew");
  return getEvalueBien(data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
};

export const handleVerifyCode = (verificationId, verificationCode) => {
  const credential = PhoneAuthProvider.credential(
    verificationId,
    verificationCode
  );
  return credential;
};

export const signInWithCredential = (credentials) => {
  // return auth.signInWithCredential(credentials).then((res) => {
  return signInWithCredentialFirebase(auth, credentials).then((res) => {
    return res.user.uid;
  });
};

export const getUserWithUID = async (uid) => {
  const docSnap = await getDoc(doc(db, "users", uid));
  if (docSnap.exists) {
    return { uid, ...docSnap.data() };
  } else {
    return false;
  }
};

export const updateUserWithUID = async (uid, data) => {
  setDoc(doc(db, "users", uid), data, { merge: true })
    .then((res) => {
      console.log(res);
      return true;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
};

export const createRapportEvaluation = async (rapportsEvaluations) => {
  return setDoc(collection(db, "RapportsEvaluations"), rapportsEvaluations)
    .then((res) => res.id)
    .catch(() => false);
};

export const sendEmailJson = (emailJson) => {
  const sendEmail = httpsCallable(functions, "useSendEmail");
  sendEmail(emailJson);
};

export const zohoPostLead = (data) => {
  const postZoho = httpsCallable(functions, "useZohoPostNewLead");
  postZoho(data);
};

export const updateUserCourtier = async (uid, recommandations) => {
  // await db.collection("users").doc(uid).update({ recommandations });
  const userRef = doc(db, "users", uid);
  await updateDoc(userRef, { recommandations });
};

export const handleSendCode = (phoneNumber, appVerifier) => {
  return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    .then((confirmationResult) => {
      return confirmationResult;
    })
    .catch((error) => {
      console.log("error", error);
      Swal.fire("Erreur", error?.message, "error");
      return false;
    });
};
