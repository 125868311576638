import PublicLayout from "./publicLayout";
import { Suspense, useEffect, useState } from "react";
import PrivateLayout from "./privateLayout";
import Loading from "../components/loading";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import estimationActions from "../redux/estimation/actions";
import userActions from "../redux/user/actions";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebaseService";

export const MainLayout = () => {
  const { isLoaded = false, isEmpty = true } = useSelector(
    (state) => state.user.auth
  );
  const { verificationCode = false } = useSelector((state) => state.estimation);
  const { redirectTo = "" } = useSelector((state) => state.estimation);

  const [isFromLp, setFromLp] = useState(true);
  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = new URLSearchParams(search).get("token");
    if (token) {
      auth
        .signInWithCustomToken(token)
        .then((res) => {
          navigate("/evaluation-bien");
          setFromLp(false);
        })
        .catch((err) => {
          setFromLp(false);
        });
      setFromLp(true);
    } else {
      setFromLp(false);
    }
  }, [search]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch({
          type: userActions.GET_CURRENT_USER,
          payload: {
            user: {
              uid: user?.uid,
            },
          },
        });
      } else {
        dispatch({
          type: userActions.SET_STATE,
          payload: {
            auth: {
              isEmpty: true,
              isLoaded: true,
            },
          },
        });
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (redirectTo && redirectTo.includes("vmz")) {
      dispatch({
        type: estimationActions.SET_STATE,
        payload: {
          verificationCode: false,
          redirectTo: "",
        },
      });
      navigate(redirectTo);
    }
  }, [redirectTo]);

  return (
    <>
      {!isLoaded || isFromLp ? (
        <Loading />
      ) : (
        <Suspense fallback={<Loading />}>
          {isEmpty || verificationCode ? <PublicLayout /> : <PrivateLayout />}
        </Suspense>
      )}
    </>
  );
};

export default MainLayout;
