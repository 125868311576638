import { db } from "../../firebaseService";
import swal from "sweetalert2";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export const getReport = async (reportId) => {
  try {
    const report = await getDoc(doc(db, "RapportsEvaluations", reportId));
    if (report.exists) {
      return { id: report.id, ...report.data() };
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const updateAvis = async (idRapport, avis, t) => {
  try {
    const docRef = doc(db, "RapportsEvaluations", idRapport);
    await updateDoc(docRef, {
      avisClient: avis,
    });
    swal.fire(t("swal.16"), t("swal.17"), "success");
  } catch (error) {
    console.error("Error updating document: ", error);
  }
};
