const userActions = {
  SET_STATE: "user/SET_STATE",
  GET_CURRENT_USER: "user/GET_CURRENT_USER",
  AUTH_STATE_CHANGED: "user/AUTH_STATE_CHANGED",
  SET_ISEMPTY: "user/SET_ISEMPTY",
  SET_MES_EVALUATIONS: "user/SET_MES_EVALUATIONS",
  GET_MORE_EVALUATIONS: "user/GET_MORE_EVALUATIONS",
  DELETE_EVALUATION: "user/DELETE_EVALUATION",
};
export default userActions;
