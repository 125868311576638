import { Navigate, Route, Routes } from "react-router-dom";
import { lazy } from "react";

const HomePage = lazy(() => import("../../pages/homePage"));
const Blogs = lazy(() => import("../../pages/blogs"));
const PrivacyPolicyPage = lazy(() => import("../../pages/privacy"));
const AccommodationPage = lazy(() => import("../../pages/accommodationPage"));
const EstimateFormPage = lazy(() => import("../../pages/estimatFormPage"));
const EstimationImmobiliere = lazy(() =>
  import("../../pages/estimationImmobiliere")
);
const ThankyouPage = lazy(() =>
  import("../../pages/estimatFormPage/steps/ThankyouPage")
);
const Footer = lazy(() => import("../../components/footer"));
const CookiesModal = lazy(() => import("../../components/cookiesModal"));
const TermOfService = lazy(() => import("../../pages/termOfService"));
const Support = lazy(() => import("../../pages/contact/Support"));

const PublicHeader = lazy(() => import("../../components/publicHeader"));

const PublicLayout = () => {
  return (
    <div className={"d-flex flex-column min-vh-100"}>
      <div className={"flex-fill"}>
        <CookiesModal />
        <main>
          <PublicHeader />
          <div className={"bg-gray-100 min-vh-100 d-flex flex-column"}>
            <Routes>
              <Route path="/" element={<HomePage />} exact />
              <Route path="/blog" element={<Blogs />} />
              <Route path={"/dataPolicy"} element={<PrivacyPolicyPage />} />
              <Route path={"/accommodation"} element={<AccommodationPage />} />
              <Route path={"estimate-form"} element={<EstimateFormPage />} />
              <Route
                path="/estimation-mobiliere"
                element={<EstimationImmobiliere />}
              />
              <Route path="/redirectUrl" element={<ThankyouPage />} />
              <Route path={"/termofservice"} element={<TermOfService />} />
              <Route path={"/contact"} element={<Support />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default PublicLayout;
