import reportActions from "./actions";

const initialState = {
  report: {},
};

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case reportActions.SET_REPORT:
      return { ...state, report: action.payload };
    default:
      return state;
  }
}
