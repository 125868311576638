import { db } from "../../firebaseService";
import { collection, query, limit, orderBy, getDocs } from "firebase/firestore";

export const getLatestEstimation = async () => {
  const querySnapshot = query(
    collection(db, "RapportsEvaluations"),
    orderBy("dateCreation", "desc"),
    limit(12)
  );
  const rowEstimations = await getDocs(querySnapshot);
  const estimations = [];
  if (rowEstimations.empty) {
    return estimations;
  } else {
    rowEstimations.forEach((item) => {
      const data = item.data();
      estimations.push({ id: item.id, ...data });
    });
    return estimations;
  }
};
