import actions from "./actions";

const initialState = {
  formations: [],
  loading: false,
  selectedFormation: null,
};

export default function formationReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SET_LOADING:
      return { ...state, loading: action.payload };
    case actions.SET_SELECTED_FORMATION:
      return { ...state, selectedFormation: action.payload };
    case actions.SET_FORMATIONS:
      return { ...state, formations: action.payload };
    default:
      return state;
  }
}
