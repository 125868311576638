import { db, auth } from "../../firebaseService";
import {
  collection,
  query,
  getDocs,
  getDoc,
  doc,
  deleteDoc,
  orderBy,
  limit,
  where,
  startAt,
} from "firebase/firestore";

export const logoutUser = async () => {
  return auth.signOut();
};

export const getMyEvaluations = async (uid, l = 100) => {
  try {
    const evaluations = await getDocs(
      query(
        collection(db, "RapportsEvaluations"),
        where("userID", "==", uid),
        orderBy("dateCreation", "desc"),
        limit(l + 1)
      )
    );

    if (evaluations.empty) {
      return [];
    } else {
      const mesEvaluations = evaluations.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const isMore = mesEvaluations.length > l;
      let nextEvaluation = null;
      if (isMore) {
        nextEvaluation = mesEvaluations[mesEvaluations.length - 1]?.id;
      }
      return {
        mesEvaluations: mesEvaluations.slice(0, l),
        isMore,
        nextEvaluation,
      };
    }
  } catch (error) {
    console.log("getMyEvaluations -> error", error);
    return [];
  }
};

export const getMoreEvaluations = async (uid, nextEvaluation, l = 10) => {
  try {
    const nextEvaluationDoc = await getDoc(
      doc(db, "RapportsEvaluations", nextEvaluation)
    );

    const evaluations = await getDocs(
      query(
        collection(db, "RapportsEvaluations"),
        where("userID", "==", uid),
        orderBy("dateCreation", "desc"),
        startAt(nextEvaluationDoc),
        limit(l + 1)
      )
    );

    if (evaluations.empty) {
      return [];
    } else {
      const mesEvaluations = evaluations.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const isMore = mesEvaluations.length > l;
      let nextEvaluation = null;
      if (isMore) {
        nextEvaluation = mesEvaluations[mesEvaluations.length - 1]?.id;
      }
      return {
        newEvaluations: mesEvaluations.slice(0, l),
        isMore,
        nextEvaluation,
      };
    }
  } catch (error) {
    console.log("getMyEvaluations -> error", error);
    return [];
  }
};

export const deleteEvaluation = async (evaluationId) => {
  try {
    await deleteDoc(doc(db, "RapportsEvaluations", evaluationId));
    return true;
  } catch (error) {
    console.log("deleteEvaluation -> error", error);
    return false;
  }
};
