import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";

import store from "./redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "./assets/styles/app.scss";
import Loader from "./components/loader";
import { Suspense } from "react";
import MainLayout from "./layout/mainLayout";

const App = () => {
  return (
    <BrowserRouter>
      <ReduxProvider store={store}>
        <Suspense fallback={<Loader />}>
          <MainLayout />
        </Suspense>
      </ReduxProvider>
    </BrowserRouter>
  );
};
export default App;
